import React, { FC, useState } from 'react';

import { Bars } from '@.partials';
import { Filter3, FilterData } from '@.ui';
import { analyticsStoreSelectors } from '@/Store';
import { PreferableProductsStatistic } from '@/Types/analytics';

import S from './styles.module.scss';

interface PreferableProductsChartProps {
    data: PreferableProductsStatistic;
}

enum FilterType {
    AGE = 'age',
    SEX = 'sex',
}

export const PreferableProductsChart: FC<PreferableProductsChartProps> = ({ data }) => {
    const [ageId, setAgeId] = useState<number | undefined>(-1);
    const [genderId, setGenderId] = useState<number | undefined>(-1);

    const filterBestCombinationIngredients = analyticsStoreSelectors.use.filterPreferableProducts();

    const getFilterValues = (item: FilterData, filterType: FilterType) => {
        if (filterType === FilterType.AGE) {
            const newAgeId = item.age_id ? Number(item.age_id) : undefined;

            setAgeId(newAgeId);

            return { ageId: newAgeId, genderId };
        }

        if (filterType === FilterType.SEX) {
            const newGenderId = item.sex_id ? Number(item.sex_id) : undefined;

            setGenderId(newGenderId);

            return { ageId, genderId: newGenderId };
        }

        return { ageId: undefined, genderId: undefined };
    };

    const onChangeFilter = (item: FilterData, filterType: FilterType) => {
        filterBestCombinationIngredients(getFilterValues(item, filterType));
    };

    return (
        <div>
            <h3 className={S.title}>Preferable Products</h3>
            <div className={S.filtersWrapper}>
                <Filter3
                    className={S.filter}
                    onChange={(age) => onChangeFilter(age, FilterType.AGE)}
                    fromData={{
                        ages: data.ages,
                    }}
                    isSaveInQuery={false}
                    resetOptionName="All ages"
                >
                    <Filter3.Ages visibleLabel={''} visibleName="All ages" />
                </Filter3>
                <Filter3
                    className={S.filter}
                    onChange={(sex) => onChangeFilter(sex, FilterType.SEX)}
                    fromData={{
                        sexes: data.genders,
                    }}
                    isSaveInQuery={false}
                    resetOptionName="All genders"
                >
                    <Filter3.Sexes visibleLabel={''} visibleName="All genders" />
                </Filter3>
            </div>

            <Bars data={data.dataset} isHorizontal axisDataName="Products" isHideFractionalTicks />
        </div>
    );
};
